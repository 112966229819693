import React from 'react'
import { analyticsEvent } from '../../lib/utils'

const Email = ({ children, classNames, addr }) => {
  const obfuscate = () => {
    return addr.split('').reverse().join('')
  }

  const deobfuscate = () => {
    return addr
  }

  const handleClick = (e) => {
    e.preventDefault()
    analyticsEvent('click', { name: 'email' })
    window.location.href = `mailto:${deobfuscate()}`
  }

  return (
    <button className={classNames || ''} onClick={handleClick}>{children} <span style={{ direction: 'rtl', unicodeBidi: 'bidi-override'}}>{obfuscate()}</span></button>
  )
}

export default Email
