import React from 'react'

function year() {
  const date = new Date();
  return date.getFullYear();
}

const Copyright = () => (
  <section className="p-2 md:py-2 bg-black text-grey-light leading-normal text-sm">
    <div className="container mx-auto">
      <div className="flex">
        <div className="w-full text-center">
          Copyright &copy; {year()} Jump Around Gymnastics
        </div>
      </div>
    </div>
  </section>
)

export default Copyright
