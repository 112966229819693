function extractAge(str) {
  const YEAR_REGEX = /^P([0-9].)Y/
  return str.length ? Number(str.match(YEAR_REGEX).pop()) : str
}

function analyticsEvent(eventType, params) {
  if (typeof window.gtag === 'function') {
    window.gtag("event", eventType, params)
  }
}

export {
  extractAge,
  analyticsEvent
}
